// 闯关奖励
export const mapgames = {
  1: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  2: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  3: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  4: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],

  5: [
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "3", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "2", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "10", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "1", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
    { name: "兑换币", num: "5", img: "item100500000001" },
  ],
};

// 礼包购买
export const shoplist = [
  {
    name: "惊喜礼包",
    limit: 0,
    price: 6,
    list: [
      { name: "盒子", num: 10, img: "bicon" },
    ],
  },
  {
    name: "精怪礼包",
    limit: 0,
    price: 30,
    list: [
      { name: "盒子", num: 50, img: "bicon" },
    ],
  },
  {
    name: "鬼马礼包",
    limit: 0,
    price: 98,
    list: [
      { name: "盒子", num: 180, img: "bicon" },
    ],
  },
  {
    name: "至上礼包",
    limit: 0,
    price: 198,
    list: [
      { name: "盒子", num: 400, img: "bicon" },
    ],
  },
  {
    name: "无限礼包",
    limit: 0,
    price: 328,
    list: [
      { name: "盒子", num: 1000, img: "bicon" },
    ],
  },
];

// 礼包列表
import jxlb_6 from "@/assets/img/jxlb_6.png";
import jxlb_30 from "@/assets/img/jxlb_30.png";
import gmlb_98 from "@/assets/img/gmlb_98.png";
import zslb_198 from "@/assets/img/zslb_198.png";
import wxlb_328 from "@/assets/img/wxlb_328.png";
import btn_6 from "@/assets/img/btn_6.png";
import btn_30 from "@/assets/img/btn_30.png";
import btn_98 from "@/assets/img/btn_98.png";
import btn_198 from "@/assets/img/btn_198.png";
import btn_328 from "@/assets/img/btn_328.png";

import dnw_gift from "@/assets/img/逗你玩_礼物.png";
import ddm_gift from "@/assets/img/捣蛋帽_礼物.png";
import dmw_gift from "@/assets/img/大魔王_礼物.png";
import any_gift from "@/assets/img/爱你呀_礼物.png";
import wqjx_gift from "@/assets/img/顽趣惊喜_礼物.png";

import zgtx from "@/assets/img/整蛊头像框.png";
import zgltqp from "@/assets/img/整蛊聊天气泡.png";
export const giftList = [
  {
    name: "惊喜礼包", img: jxlb_6, btnIcon: btn_6, price: 6,
    list: [
      { name: "盒子", num: 10, img: "bicon" },
      { name: "逗你玩(礼物)", num: 1, img: dnw_gift },
      { name: "整蛊头像框(1天)", num: 1, img: zgtx },
    ],
  },
  {
    name: "惊喜礼包", img: jxlb_30, btnIcon: btn_30, price: 6,
    list: [
      { name: "盒子", num: 50, img: "bicon" },
      { name: "捣蛋帽(礼物)", num: 1, img: ddm_gift },
      { name: "整蛊头像框(1天)", num: 2, img: zgtx },
      { name: "整蛊聊天气泡(1天)", num: 2, img: zgltqp },
    ],
  },
  {
    name: "鬼马礼包", img: gmlb_98, btnIcon: btn_98, price: 6,
    list: [
      { name: "盒子", num: 180, img: "bicon" },
      { name: "大魔王(礼物)", num: 1, img: dmw_gift },
      { name: "整蛊头像框(1天)", num: 5, img: zgtx },
      { name: "整蛊聊天气泡(1天)", num: 5, img: zgltqp },
    ],
  },
  {
    name: "至上礼包", img: zslb_198, btnIcon: btn_198, price: 6,
    list: [
      { name: "盒子", num: 400, img: "bicon" },
      { name: "爱你呀(礼物)", num: 1, img: any_gift },
      { name: "整蛊头像框(1天)", num: 10, img: zgtx },
      { name: "整蛊聊天气泡(1天)", num: 10, img: zgltqp },
    ],
  },
  {
    name: "无限礼包", img: wxlb_328, btnIcon: btn_328, price: 6,
    list: [
      { name: "盒子", num: 1000, img: "bicon" },
      { name: "顽趣惊喜(礼物)", num: 1, img: wqjx_gift },
      { name: "整蛊头像框(1天)", num: 15, img: zgtx },
      { name: "整蛊聊天气泡(1天)", num: 15, img: zgltqp },
    ],
  },
];

// 兑换商店
import mgjy from "@/assets/img/玫瑰记忆座位框.png";
import mmay from "@/assets/img/绵绵爱意座位框.png";
import yhdr from "@/assets/img/约会达人聊天气泡.png";
import sqfh from "@/assets/img/三千繁华聊天气泡.png";
import hrym from "@/assets/img/花容月貌铭牌.png";
import fhyf from "@/assets/img/凤凰于飞铭牌.png";
export const convertShop = [
  {
    name: "玫瑰记忆座位框", num: 1, img: mgjy, spend: 100, limit: 50, changeNum: 0, price: 100
  },
  {
    name: "绵绵爱意座位框", num: 1, img: mmay, spend: 200, limit: 2, changeNum: 0, price: 200
  },
  {
    name: "约会达人聊天气泡", num: 1, img: yhdr, spend: 1000, limit: 50, changeNum: 0, price: 1000
  },
  {
    name: "三千繁华聊天气泡", num: 2, img: sqfh, spend: 300, limit: 50, changeNum: 0, price: 300
  },
  {
    name: "花容月貌铭牌", num: 2, img: hrym, spend: 300, limit: 50, changeNum: 0, price: 300
  },
  {
    name: "凤凰于飞铭牌", num: 1, img: fhyf, spend: 500, limit: 50, changeNum: 0, price: 500
  },
];
