<template>
  <div class=''>
    <div class="top">
      <div class="tm"></div>
      <img
        :src="showgif ? 'https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/tim.gif' : 'https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/tim.png'"
        :style="tmstyle" :class="{ 'tm': true, 'trans': curent && transArr.includes(Number(curent.num)) && !restart }">
      <img :src="boximg(i)" alt="" :style="{ top: item.top, left: item.left }" :class="boxstyle(i)"
        v-for="(item, i) in list" :key="i">
    </div>
    <div class="sz-bg">
      <!-- 骰子初始状态 -->
      <img class="sz" v-show="!run && !resImg" src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/sz.png"
        alt="">
      <!-- 骰子gif -->
      <img class="run" v-show="run" src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/run.gif" alt="">

      <img class="sz" v-show="resImg" :src="resImg" alt="">

      <div class="btn-buy" @click="linkTo('listbuy')"></div>
      <div class="lave">剩余：{{ bnum || 0 }}</div>
      <div class="kefu" @click="showRule"></div>
      <div class="rule" @click="tokefu"></div>
    </div>
    <!-- 大富翁 -->
    <div class="mask-bg" v-if="roundimg">
      <img :src="roundimg" alt="" class="round-img">
    </div>
    <div class="monopoly">
      <div class="run end" v-if="spend === 0">恭喜您已通关</div>

      <div class="run" v-else @click="torun">本次需要消耗：<img
          src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/bicon.png" alt="" class="icon-img">X{{ spend }}
      </div>
      <!-- 签到模块 -->
      <div class="con">
        <div class="one">
          <div class="single" v-for="(item, i) in sum" :key="i">
            <div class="num">{{ item.num }}</div>
            <div class="target">￥{{ item.limit }}</div>
            <div :class="{ 'press': true, 'active': used_bnum >= item.limit }"></div>
            <img src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/bicon.png" alt="" class="box-img">
            <div :class="{ 'btn': true, 'gray': used_bnum < item.limit || bigprize && bigprize[i + 1] }"
              v-debounce="() => { torecive(i + 1, 3) }">{{ isAlready[i + 1] ? '已' : '' }}领取</div>
          </div>

        </div>
        <div class="two" ref="singin">
          <div :class="{ 'sign': true, 'had': singin && singin[item] }" v-for="(item, i) in 7" :key="i"
            v-debounce="() => { torecive(item, 1) }">
            <img src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/had.png" alt="" class="rev-icon">
            <img src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/bicon.png" alt="">
            <div :class="{ 'day': true, 'gray': isSign[item] }">day{{ item }}</div>
          </div>
        </div>
      </div>
      <!-- 礼包购买模块 -->
      <!-- <div class="tit" ref="listbuy"></div> -->
      <!-- <div class="con-item" v-for="(item,i) in shoplist">
        <div class="l-img">
          <img src="https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/box_img.png" alt="" >
        </div>
        <div class="r-info">
          <div class="limit" v-if="item.limit >0">限购{{order &&  order[i+1]  ? order[i+1] :0}}/{{item.limit}}</div>
          <div class="limit" v-else>不限购</div>
          <div class="name">{{item.name}}</div>
          <div class="price">￥{{item.price}}</div>
          <div class="buy" @click="buy(item,i)"></div>
        </div>
      </div> -->
      <div class="con-box">
        <div class="con-box-item" v-for="(item, i) in giftList" :key="i">
          <img class="gift-img" :src="item.img" alt="">
          <div class="btn-box" @click="buy(item, i)">
            <img class="btn-img" :src="item.btnIcon" alt="">
          </div>
        </div>
      </div>

      <beian></beian>
    </div>


    <short @linkTo="linkTo" />
    <tobuy />
  </div>
</template>
<script>
import './index.less'
import data from "@/assets/js/data.js"
import { mapgames, shoplist, giftList } from "@/assets/js/gift.js"
import { lottery } from '@/utils/api.js'
import { mapState } from 'vuex'
// import  './boxposition.less'
import tobuy from '@/components/Modal/tobuy'
import short from '@/components/Modal/short'
import beian from '@/components/content/beian'
export default {
  name: '',
  components: { short, tobuy, beian },
  data() {
    return {
      isSign: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false
      },
      // 控制提姆gif显示
      showgif: false,
      // 礼包数据
      shoplist: shoplist,
      giftList: giftList,
      // 地图礼盒定位数据
      list: data,
      // 是否展示骰子动图
      run: false,
      // 骰子结果图片
      resImg: '',
      // 礼盒图片
      src1: 'https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/pbox1.png',
      src2: 'https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/blue.png',
      // 累计消费奖励
      sum: [{ limit: 200, num: 5 }, { limit: 1000, num: 10 }, { limit: 2000, num: 15 }, { limit: 3000, num: 20 }, { limit: 5000, num: 25 },],
      transArr: [5, 6, 7, 8, 9, 10, 11, 12, 19, 20, 21, 22, 23, 24],
      // 第几关提示图片
      // roundimg:'https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/r2.png',
      roundimg: '',
      // 提姆和所有礼包回到初始状态
      restart: false,
      // 摇骰子前为最后大奖
      curentend: false,
      isAlready: [false, false, false, false, false]
    }
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo?.info,
      // 阶段奖励
      bigprize: (state) => state.userInfo?.prize?.[3] || null,
      // 签到奖励领取情况
      singin: (state) => state.userInfo?.prize?.[1] || null,
      // 地图游戏轮数及步数记录
      curent: (state) => state.userInfo?.curent || null,
      //  本次活动累计消费金额
      used_bnum: (state) => state.userInfo?.used_bnum || 0,
      // 签到天数
      login_num: (state) => state.userInfo?.login_num || 0,
      // 已购买礼包
      order: (state) => {
        if (Array.isArray(state.userInfo?.order)) {
          return null
        } else {
          return state.userInfo?.order
        }
      },
      // 剩余次数
      bnum: (state) => {
        return state.userInfo?.bnum || 0
      },

    }),
    // 提姆位置样式
    tmstyle() {
      if (!this.curent || this.curent?.num < 1 || this.restart) return ''

      let index = this.curent?.num - 1;
      let item = this.list[index];

      let l = (item.left.split('rem')[0] - 0.3) + 'rem'
      if (index == 7) {
        return { top: '10.1rem', left: '3.8rem' }
      }
      return { top: item.top, left: l }
    },
    // 当前玩骰子需要消耗的盒子数量
    spend() {
      const arr = [5, 10, 20, 50, 80]
      let r = this.curent?.rid || 1
      if (r == 5 && this.curent?.num >= 24) {
        return 0
      }
      return arr[r - 1]
    }
  },
  methods: {
    // 关卡奖励图片
    boximg(i) {
      let index = Number(this?.curent?.rid || 1);
      if ((this?.curent?.num >= 24 || this.curentend) && index < 5) {
        index = index + 1
      }
      return mapgames?.[index]?.[i]?.img ? `https://wcdn.tomatogames.com/web/guonei/sezg/pop-icon/${mapgames?.[index]?.[i]?.img}.png` : this.src1
      // 当前关卡大奖图片地址
      let bigest = mapgames?.[index]?.[23]?.img ? `https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${mapgames?.[index]?.[23]?.img}.png` : this.src1
      if (i == 23) {
        return bigest
      } else {
        return [9, 19].includes(i) ? this.src2 : this.src1
      }

    },
    tokefu() {
      window.open(
        "https://h.dadu888.cn/smart-teeth/index.html"
      );
    },
    boxstyle(i) {
      // 1.当前步数大于i, &&  (this?.curent?.num != 24 || (i==23 &&  this?.curent?.num==24))
      let hide = this?.curent?.num >= i + 1 && !this.restart
      return { 'box': true, 'hide': hide }
    },
    showRule() {
      this.$store.commit('SETVAL', { dialog: 'rule' })
    },
    torun() {
      // 惊吓盒子是否足够
      if (this.bnum < this.spend) {
        this.$store.commit('SETVAL', { dialog: 'short' })
        return
      }
      if (!this.curent?.num && !this.curent?.rid) {
        this.$store.commit("SETVAL", {
          userInfo:
          {
            ...this.$store.state.userInfo,
            curent: {
              num: 0,
              rid: 1
            }
          }
        })
      }
      let userInfo = this.$store.state.userInfo
      userInfo.bnum = userInfo.bnum - this.spend;
      this.$store.commit('SETVAL', { userInfo: userInfo })
      // 已经通关提示
      // if(this.curent?.rid && this.curent?.num >=24 ) {
      //   this.$toast.success('恭喜您已经通关了')
      // }
      const run = () => {
        this.resImg = ''
        this.run = true
        setTimeout(() => {
          let data = Math.floor(Math.random() * 6) + 1
          this.run = false
          this.resImg = `https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/t${data}.png`;
          this.showgif = true
          setTimeout(() => {
            let index = this.curent.rid
            let starnum = this.curent.num >= 24 ? 0 : this.curent.num
            let endindex = Number(starnum) + Number(data)
            let arr = mapgames[index].slice(starnum, endindex)
            let sum = arr.reduce(function (acc, obj) {
              return acc + Number(obj.num)
            }, 0)
            this.$store.state.coinNum += sum
            // 动画模拟
            this.curentend = this.$store.state.userInfo.curent.num >= 24
            for (let i = 0; i < data; i++) {
              let vm = this
              setTimeout(function () {
                if (vm.curent.num >= 24 && vm.curent.rid < 5) {
                  index = Number(index) + 1
                }
                let userInfo = vm.$store.state.userInfo
                userInfo.curent.num = userInfo.curent.num < 24 ? userInfo.curent.num - 0 + 1 : 1;
                userInfo.curent.rid = userInfo.curent.num >= 24 ? userInfo.curent.rid - 0 + 1 : userInfo.curent.rid;
                vm.$store.commit('SETVAL', { userInfo: userInfo })
                vm.$nextTick(() => {
                  vm.restart = false
                })
                if (i == data - 1) {
                  setTimeout(() => {
                    vm.showgif = false
                    vm.$store.commit('SETVAL', { prizecon: arr })
                    vm.$store.commit('SETVAL', { dialog: 'success' })
                    // vm.$store.dispatch('getUserInfo').then(res=>{
                    //   vm.curentend = false
                    // })
                  }, 1000)
                }
              }, i * 500)
            }
          }, 1000)
        }, 1000);
      }
      // 是否开启另一关
      // if(this?.curent?.num >= 24 || (this?.curent?.num == 0 && this?.curent?.rid == 1)){
      //   this.restart = true
      //   this.roundimg =   `https://wcdn.tomatogames.com/web/guonei/sezg/foolsday/r${this?.curent?.num >= 24 ? this?.curent?.rid-0+1 : 1}.png` 

      //   setTimeout(()=>{
      //     this.roundimg  = ''
      //     run()
      //   },1000)
      // }else {
      //   run()
      // }
      run()


    },
    torecive(index, type) {
      // 签到条件判断
      // if(type == 1  && (this.login_num < index || this.singin && this.singin[index]) ) return
      let arr = []
      if (!this.$store.state.userInfo?.bnum) {
        this.$store.state.userInfo = {
          ...this.$store.state.userInfo,
          bnum: 0
        }
      }
      if (type == 1) {
        if (!this.isSign[index]) {
          console.log('签到', '签到成功');
          this.$store.state.userInfo.bnum += 10
          this.$toast('签到成功，获得10个盒子！')
        }
        this.isSign[index] = true

        arr = [{ name: '惊吓盒子', num: 5, img: 'bicon' }]
      } else if (type == 3) {
        arr = [{ name: '惊吓盒子', num: this.sum?.[index - 1]?.num, img: 'bicon' }]
        if (!this.isAlready[index]) {
          this.$store.state.userInfo.bnum += this.sum?.[index - 1]?.num
          this.isAlready[index] = true
          this.$toast('领取成功！')
        }
      }
      // 1签到 2兑换 3领阶段大奖
      // this.$store.dispatch('getPrize',{type:type,rid:index}).then(data=>{
      //   let arr = []
      //   if(type == 1){
      //     arr = [{name:'惊吓盒子',num:5,img:'bicon'}]
      //   }else if(type == 3){
      //     arr = [{ name:'惊吓盒子',num:this.sum?.[index-1]?.num,img:'bicon'}]
      //   }
      //   this.$store.commit('SETVAL',{prizecon:arr})
      //   this.$store.commit('SETVAL',{dialog:'success'})

      // }).catch(err=>{
      //   this.$toast.fail(err.msg || '领取失败！')
      // })
    },
    linkTo(type) {
      this.$store.commit('SETVAL', { dialog: '' })
      window.scrollTo({
        top: this.$refs[type].offsetTop - 100,
        behavior: "smooth"
      })
    },
    buy(item, index) {
      // let  token = localStorage.getItem("token")
      // // 是否登录
      // if(!token) {
      //   this.$toast('请从游戏内进入！')
      //   // this.$store.commit("SETVAL", { loginStep: "logintype" });
      //   return
      // }
      // if( item.limit > 0 && this?.order?.[index+1] >= item.limit  ){
      //   this.$toast('已达购买上限！')
      //   return
      // }
      // item.index = index + 1
      this.$store.commit('SETVAL', { dialog: 'buy' })
      this.$store.commit('SETVAL', { cgift: item })
    }

  },
  mounted() {
  },
}
</script>
<style scoped lang='scss'></style>