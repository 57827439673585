<template>
  <div class='convert-shop'>
    <div class="top2">
      <div class="rule" @click="tokefu"></div>
      <!-- <div class="address" @click="toaddress"></div> -->
      <div class="kefu" @click="showRule"></div>
    </div>
    <!-- 兑换商店 -->
    <div class="tit tit2"></div>
    <div class="shop">
    
      <div class="box-num">剩余兑换币数量：<span class="num">{{this.$store.state.coinNum}}</span></div>
      <div class="wrap-list">
        <div class="list-item" v-for="(item,i) in list">
          <div class="g-img">
            <!-- <img :src="`https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${item.img}.png`" alt="" @click="storeDetail(item)"> -->
            <!-- <div class="num">X{{item.num}}</div> -->
            <img :src="item.img" alt="" @click="storeDetail(item)">
            <div class="g-name">{{ item.name }}</div>
          </div>
          <div class="c-num">可兑换：{{item.changeNum}}/{{item.limit}}</div>
          <div class="btn" @click="toconvert(item,i+1)">X{{item.spend}}
            <img class="star" src="https://wcdn.tomatogames.com/web/guonei/sezg/pop-icon/item100500000001.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <beian class="beian"></beian>

    <convert :gift="gift" :index="index" />
    <store-detail :store="store"></store-detail>

  </div>
</template>
<script>
  import  {mapState} from 'vuex'
  import {convertShop} from "@/assets/js/gift.js"
  import convert from "@/components/Modal/convert"
  import storeDetail from "@/components/Modal/storeDetail"
  import beian from '@/components/content/beian'
  import  './index.less'
  export default {
    name: '', 
    data(){
      return {
        list:convertShop,
        gift:{
          name:'',
          price:0,
          list:[]
        },
        store: {},
        index:0
      }
    },
    components: {convert, storeDetail, beian},
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo?.info,
        // 兑换记录
        history:(state) => state.userInfo?.prize?.[2] || null,
        // 剩余次数
        bnum:(state) => {
          return  state.userInfo?.bnum || 0
        },
      }),

    },
    methods:{
      tokefu() {
        window.open(
          "https://customer.zanbugames.com/website/games/youxi/html/index.html?v=1.0"
        );
      },
      showRule(){
        this.$store.commit('SETVAL',{dialog:'rule'})
      }, 
      toaddress() {
        this.$store.commit('SETVAL',{dialog:'address'})
      },
      toconvert(item,i){
        // let  token = localStorage.getItem("token")
        // // 是否登录
        // if(!token) {
        //   this.$toast('请从游戏内进入！')
        //   // this.$store.commit("SETVAL", { loginStep: "logintype" });
        //   return
        // }
        if(this.$store.state.coinNum < item.spend){
          this.$toast('剩余兑换币不足！')
          return
        } 
        
        this.index = i 
        this.gift = item
        this.$store.commit('SETVAL',{dialog:'convert'})
      },
      storeDetail(store) {
        this.store = store
        this.$store.commit('SETVAL',{dialog:'storeDetail'})
      }
      
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
</style>